import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import SignInModal from "./SignInModal";
import SignUpModal from "./SignUpModal";
import Logo from "./images/xorbit-logo.png";
import useAuth from "src/hooks/useAuth";
import Ternary from "src/components/ternary";
import { useNavigate, useParams } from "react-router";
import { useSnackbar } from "notistack";
import NotificationsUserPopover from "src/layouts/shared/header/NotificationsUserPopover";
import AccountUserPopover from "src/layouts/shared/header/AccountPopoverUser";
import { PATH_USER } from "src/routes/paths";

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 1rem;
`;

const NavItem = styled.li`
  list-style: none;
  margin: 0 1rem;

  a {
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
      color: #fff;
    }
  }
`;

const MobileNavToggle = styled.button`
  display: block;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: #fff;
  }
`;

const MobileNav = styled.ul`
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  transition: all 0.3s ease;
  transform: ${({ isOpen }) =>
    isOpen ? "translateX(0)" : "translateX(-100%)"};
`;

const NavBar = ({ isUser }) => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const mobileNavRef = useRef(null);
  const { user, logout, isAdmin } = useAuth();
  const isLoggedIn = !user;
  const { uname } = useParams();

  const openSignInModal = () => {
    setIsSignInOpen(true);
    setIsSignUpOpen(false);
  };

  const openSignUpModal = () => {
    setIsSignUpOpen(true);
    setIsSignInOpen(false);
  };

  const closeMobileNav = () => {
    setIsMobileNavOpen(false);
  };

  useEffect(() => {
    if (uname) {
      setIsSignUpOpen(true);
    }
  }, [uname]);

  // Close mobile nav if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        mobileNavRef.current &&
        !mobileNavRef.current.contains(event.target)
      ) {
        closeMobileNav();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [atTop, setAtTop] = useState(true); // Track if at top of page

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setAtTop(true);
      } else {
        setAtTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box sx={{ width: "100%" }} className={atTop ? "" : "atTop"}>
      <Box className="navcontainer">
        <Box className="push-left NavflexBox">
          <a className="mobileLogo" href="/">
            <img src={Logo} width="150" height="" alt="logo" />
          </a>
          <Box className="logo">
            <a href="/">
              <img src={Logo} width="200" height="" alt="logo" />
            </a>
          </Box>
          <Nav className="navbar" style={{ backgroundColor: "transparent" }}>
            <MobileNavToggle
              className="hamburger"
              onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4 7a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1zm0 5a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1zm0 5a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1z" />
              </svg>
            </MobileNavToggle>
            <Ternary
              when={isUser}
              then={
                <>
                  {" "}
                  <ul className="mobileNotifctn">
                    <li className="menu-item">
                      <NotificationsUserPopover />
                    </li>
                    <li className="menu-item">
                      <AccountUserPopover />
                    </li>
                  </ul>
                </>
              }
            />

            <ul id="primary-menu" className=" nav-menu">
              <li className="menu-item">
                <a
                  className="nav__link"
                  href={!isLoggedIn && PATH_USER.auctionBidding.root}
                  onClick={isLoggedIn && openSignInModal}
                >
                  AUCTION
                </a>
              </li>
              <li className="menu-item">
                <a
                  className="nav__link"
                  href={!isLoggedIn && "/comingsoon"}
                  onClick={isLoggedIn && openSignInModal}
                >
                  SHOPPING
                </a>
              </li>
              <li className="menu-item">
                <a
                  className="nav__link"
                  href={!isLoggedIn && "/comingsoon"}
                  onClick={isLoggedIn && openSignInModal}
                >
                  GAMES
                </a>
              </li>
              <li className="menu-item">
                <a
                  className="nav__link"
                  href={!isLoggedIn && "/comingsoon"}
                  onClick={isLoggedIn && openSignInModal}
                >
                  OTC
                </a>
              </li>
              <li className="menu-item">
                <a
                  className="nav__link"
                  href={!isLoggedIn && "/comingsoon"}
                  onClick={isLoggedIn && openSignInModal}
                >
                  TRAVEL
                </a>
              </li>
              <li className="menu-item">
                <a
                  className="nav__link"
                  href={!isLoggedIn && "/comingsoon"}
                  onClick={isLoggedIn && openSignInModal}
                >
                  MARKET
                </a>
              </li>
              <li className={`menu-item ${!isUser ? "" : "no-slash"}`}>
                <a
                  className="nav__link"
                  href={!isLoggedIn && "/faq"}
                  onClick={isLoggedIn && openSignInModal}
                >
                  FAQ
                </a>
              </li>
              <Ternary
                when={isUser}
                then={
                  <>
                    <li className="menu-item">
                      <NotificationsUserPopover />
                    </li>
                    <li className="menu-item">
                      <AccountUserPopover />
                    </li>
                  </>
                }
                otherwise={
                  <>
                    {" "}
                    {isLoggedIn ? (
                      <>
                        {" "}
                        <li className="menu-item">
                          <a
                            className="nav__link"
                            href="#"
                            onClick={openSignInModal}
                          >
                            SIGN IN
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            className="nav__link"
                            href="#"
                            onClick={openSignUpModal}
                          >
                            SIGN UP
                          </a>
                        </li>
                      </>
                    ) : (
                      <>
                        {" "}
                        <>
                          {isAdmin ? (
                            <li className="menu-item">
                              <a
                                className="nav__link"
                                href="/admin/dashboard/business"
                              >
                                DASHBOARD
                              </a>
                            </li>
                          ) : (
                            <li className="menu-item">
                              <a className="nav__link" href="/user/dashboard">
                                DASHBOARD
                              </a>
                            </li>
                          )}
                        </>
                      </>
                    )}
                  </>
                }
              />
            </ul>

            <MobileNav
              className="MobilenavMenu"
              ref={mobileNavRef}
              isOpen={isMobileNavOpen}
            >
              <NavItem>
                <a
                  href={!isLoggedIn && PATH_USER.auctionBidding.root}
                  onClick={isLoggedIn && openSignInModal}
                >
                  AUCTION
                </a>
              </NavItem>
              <NavItem>
                <a
                  href={!isLoggedIn && "/comingsoon"}
                  onClick={isLoggedIn && openSignInModal}
                >
                  SHOPPING
                </a>
              </NavItem>
              <NavItem>
                <a
                  href={!isLoggedIn && "/comingsoon"}
                  onClick={isLoggedIn && openSignInModal}
                >
                  GAMES
                </a>
              </NavItem>
              <NavItem>
                <a
                  href={!isLoggedIn && "/comingsoon"}
                  onClick={isLoggedIn && openSignInModal}
                >
                  OTC
                </a>
              </NavItem>
              <NavItem>
                <a
                  href={!isLoggedIn && "/comingsoon"}
                  onClick={isLoggedIn && openSignInModal}
                >
                  TRAVEL
                </a>
              </NavItem>
              <NavItem>
                <a
                  href={!isLoggedIn && "/faq"}
                  onClick={isLoggedIn && openSignInModal}
                >
                  FAQ
                </a>
              </NavItem>
              <Ternary
                when={isUser}
                then={<></>}
                otherwise={
                  <>
                    {" "}
                    {isLoggedIn ? (
                      <>
                        {" "}
                        <NavItem>
                          <a
                            className="nav__link"
                            href="#"
                            onClick={openSignInModal}
                          >
                            SIGN IN
                          </a>
                        </NavItem>
                        <NavItem>
                          <a
                            className="nav__link"
                            href="#"
                            onClick={openSignUpModal}
                          >
                            SIGN UP
                          </a>
                        </NavItem>
                      </>
                    ) : (
                      <>
                        {" "}
                        <>
                          {isAdmin ? (
                            <NavItem>
                              <a
                                className="nav__link"
                                href="/admin/dashboard/business"
                              >
                                DASHBOARD
                              </a>
                            </NavItem>
                          ) : (
                            <NavItem>
                              <a className="nav__link" href="/user/dashboard">
                                DASHBOARD
                              </a>
                            </NavItem>
                          )}
                        </>
                      </>
                    )}
                  </>
                }
              />
            </MobileNav>
          </Nav>
        </Box>
      </Box>
      <SignInModal isOpen={isSignInOpen} setIsSignInOpen={setIsSignInOpen} />
      <SignUpModal
        uname={uname}
        setIsSignUpOpen={setIsSignUpOpen}
        isOpen={isSignUpOpen}
        setIsSignInOpen={setIsSignInOpen}
      />
    </Box>
  );
};

export default NavBar;
