import React, { useEffect, useState } from "react";
import useAuctionProductHistory from "./hooks/useFetchauctionProduct";
import { Card, Typography, Grid, Box } from "@mui/material";
import Vimeo from "@u-wave/react-vimeo";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ReactQuill from "react-quill";
import axiosInstance from "src/utils/axios";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import { PATH_USER } from "src/routes/paths";
import Page from "src/components/Page";
import AmountBidding from "./amountBidding";
import Scrollbar from "src/components/Scrollbar";
import DataHandlerTable from "src/components/data-handler/table";
import DataList from "./dataList";
import Map from "src/components/map";
import PaginationButtons from "src/components/pagination";
import Ternary from "src/components/ternary";
import solOut from "src/images/20945097-Photoroom.png";
import LabelStyle from "src/components/label-style";
import "./style.css";
import useIsDarkMode from "src/hooks/use-is-darkmode";
const headers = [
  "articles.articles.table.no",
  "Product Name",
  "Amount",
  "Status",
  "Date",
];
const Bidding = () => {
  const [data, setData] = useState({});
  const { video_url, description, product_images, name } = data || {};

  const {
    state,
    fetchData: reload,
    rowStart,
    ...rest
  } = useAuctionProductHistory();
  const { data: list, ...dataProps } = state;
  const getVimeoIdFromUrl = (url) => {
    const regex = /vimeo\.com\/(\d+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };
  const isDark = useIsDarkMode();

  useEffect(() => {
    const fetchData = async (page = 1) => {
      try {
        const { data, status } = await axiosInstance.get(
          "api/user/active-auction",
          {
            params: { page },
          }
        );
        if (status) {
          setData(data?.data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  // Extract Vimeo ID from URL if video_url is a full URL
  const vimeoId = video_url ? getVimeoIdFromUrl(video_url) : null;

  return (
    <Page
      sx={{ p: { md: 0, lg: 0, md: "10px", xs: "10px" } }}
      title="Auction Bidding"
    >
      <HeaderBreadcrumbs
        sx={{ pl: 1, mb: 0, color: "white" }}
        heading="Auction Bidding"
        links={[
          { name: "global.dashboard", href: PATH_USER.root, color: "white" },
          {
            name: "Auction Bidding",
            href: PATH_USER.root,
          },
        ]}
      />
      <Box
        sx={{ maxWidth: { xs: 350, sm: 700, md: 900, lg: 900 }, mx: "auto" }}
      >
        <Ternary
          when={data === undefined}
          then={
            <>
              {" "}
              <Box
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column", // Arrange items in a column
                  alignItems: "center", // Center align the text and image
                }}
              >
                <img
                  src={solOut}
                  style={{
                    width: 350,
                  }}
                />
                <Typography sx={{ color: "white" }}>
                  Auction is currently not active
                </Typography>
              </Box>
            </>
          }
          otherwise={
            <>
              {" "}
              <Card
                sx={{
                  p: 3,
                  boxShadow: 4,
                  borderRadius: 2,
                  maxWidth: { xs: "100%", lg: 900 },
                  backgroundColor: "rgba(255, 255, 255, .2)",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "center",
                    mb: 3,
                    fontSize: { xs: "1.5rem", lg: "2rem" }, // Adjust font size for mobile
                  }}
                >
                  {name}
                </Typography>

                <Grid
                  container
                  spacing={4}
                  sx={{ flexDirection: { xs: "column", md: "row" } }}
                >
                  {/* Left Grid: Swiper */}
                  <Grid item xs={12} md={6}>
                    <Swiper
                      modules={[Navigation, Pagination]}
                      navigation
                      pagination={{ clickable: true }}
                      spaceBetween={10}
                      slidesPerView={1}
                    >
                      {product_images?.map((image) => (
                        <SwiperSlide key={image.id}>
                          <img
                            src={image.image_url}
                            alt={`Product ${data.name}`}
                            style={{
                              width: "100%",
                              height: "auto",
                              borderRadius: "8px",
                              objectFit: "cover",
                              boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
                            }}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Grid>

                  {/* Right Grid: Product Details and Video */}
                  <Grid item xs={12} md={6}>
                    <ReactQuill
                      value={description}
                      theme="bubble"
                      readOnly
                      style={{
                        maxHeight: "250px", // Adjust for mobile
                        overflowY: "auto", // Enable scrolling for text overflow
                      }}
                    />

                    {vimeoId && <Vimeo video={vimeoId} controls responsive />}
                    <AmountBidding reload={reload} />
                  </Grid>
                </Grid>
              </Card>
            </>
          }
        />

        <Card
          className="transparentTable"
          sx={{
            mt: 2,
            py: 3,
            px: 2,
            backgroundColor: "rgba(255, 255, 255, .2)",
          }}
        >
          <Scrollbar>
            <DataHandlerTable
              name="article-table"
              headers={headers}
              dataProps={{ ...dataProps }}
            >
              <Map
                list={list}
                render={(item, i) => (
                  <DataList item={item} rowNumber={rowStart + i} />
                )}
              />
            </DataHandlerTable>
          </Scrollbar>
          <PaginationButtons {...rest} />
        </Card>
      </Box>
    </Page>
  );
};

export default Bidding;
